<template>
    <section id="dashboard-ecommerce">
      <div class="sec1 d-flex justify-content-between pb-1">
        <h1 class="text-black">Coupon Suggestion{{ search }}{{ app }}</h1>
        <div>
          <!-- <button class="btn notofication-btn mr-1">
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
            </button> -->
  
          <button id="popover-button-bottom" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1"  v-b-tooltip.hover.v-primary title="Filter"
            @click="onPopovcerOpen1()">
            <div>
              <img src="../../../assets/images/client/filter.svg" alt="Snow" />
            </div>
          </button>
          <b-popover ref="popover" target="popover-button-bottom" triggers="click" placement="Left"
            class="custom-popover pop-btn" :show.sync="popoverShow1">
            <div class="center custom-vs-select" style="border: none;width:5cm">
              <flat-pickr class="form-control" v-model="date" :config="{
                dateFormat: 'd/m/Y',
                mode: 'range'
              }" placeholder="Select Date" style="background-color: transparent"
                @input="getFilterData($event)" />
  
              <!-- <b-form-datepicker id="example-datepicker" v-model="filter" class="mb-2"></b-form-datepicker> -->
              <!-- <v-select  v-model="filter" class="" @input="handelChangeFilter" shadow placeholder="Select" :options="['All','Active','Inactive']"> -->
              <!-- </v-select> -->
            </div>
          </b-popover>
  
          <button type="button" v-b-tooltip.hover.v-primary @click="exportToCSV" title="Export CSV"
            variant="outline-primary" class="btn notofication-btn mr-1">
            <img src="../../../assets/images/client/export.svg" alt="Snow" />
          </button>
          <b-modal id="showmodal" size="md" ref="showmodal" class="m-2 custom--model">
            <template #modal-header>
              <div style="width:80%;padding: 10px 20px;" class="form-row">
                <div>
                      <div class="d-flex">
                        <div class="ml-1 mr-2">
                          <b-img v-if="checkURL(showItem.brand_logo?showItem.brand_logo.split(',')[0]:'')" v-bind="mainProps" rounded="circle" :src="showItem.brand_logo?showItem.brand_logo.split(',')[0]:''"
                          alt="Circle image" class="d-inline-block" />
                          <b-img v-else v-bind="mainProps" rounded="circle"
                          :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                          class="d-inline-block" />
                        </div>
                          <div class="mt-1">
                            <div class="font-weight-bolder h4 text-black mb-half">
                              {{ showItem.fullname ? showItem.fullname : '(not set)'  }}
                            </div>
                              <p class="font-weight-bolder mt-1">{{ showItem.email }}</p>
                          </div>
                    </div>

                    </div>
              </div>
              <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </b-button>
            </template>
            <b-row class="p-1">
              <b-col md="12">
                <b-row class="justify-content-between">
                  <b-col md="4">
                    <div>
                      <div class="mb-half">Coupon Code</div>
                      <div class="font-weight-bolder text-black">
                        <span class="font-weight-bolder h1" style="color: #3f8cff"> {{ showItem.coupon_code ? showItem.coupon_code.slice(0,15) : ''}}{{ showItem.coupon_code ? showItem.coupon_code.length > 15 ? '...' : '' : '' }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="showItem.title" md="4">
                <div class="d-flex">
                  <div>
                  <div class="mb-half">Title</div>
                    <div class="font-weight-bolder text-black mb-half">
                      {{  showItem.title   }}
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Store</div>
                  <div class="font-weight-bolder h4 text-black">{{ showItem.store }}</div>
                </div>
              </b-col>
              <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Phone Number</div>
                  <div class="font-weight-bolder h4 text-black">{{ showItem.phone_number }}</div>
                </div>
              </b-col>
              <b-col md="4" class="d-flex">
                <div>
                  <div class="mb-half">Created</div>
                  <div class="font-weight-bolder h4 text-black">{{ showItem.created_at }}</div>
                </div>
              </b-col>
              </b-row>
            </b-modal>
          <!-- <button
            id="popover-button-variant"
            :style="popoverShow ? 'background-color: black;' : ''"
            href="#"
            tabindex="0"
            type="button"
            class="btn notofication-btn mr-1"
            @click="onPopovcerOpen()"
          >
            <div>
              <img
                v-if="!popoverShow"
                id="task1"
                src="../../../assets/images/client/task-square.svg"
                alt="Snow"
              />
  
              <img
              v-else
                id="task2"
                src="../../../assets/images/client/task-light.svg"
                alt="Snow"
              />
            </div>
          </button>
  
          <b-popover
            ref="popover"
            target="popover-button-variant"
            triggers="click"
            class="custom-popover"
            :show.sync="popoverShow"
          >
            <button
              @click="onPopovcerOpen"
              class="notofication-btn"
              style="color: #408dff; margin: 5px"
              size="sm"
            >
              Cancel
            </button>
  
            <button
              type="button"
              class="btn notofication-btn"
              style="background: #f4f9fd; margin: 8px"
            >
              <img src="../../../assets/images/client/copy.png" alt="Snow" />
            </button>
            <button
              type="button"
              class="btn notofication-btn ml-1"
              style="background: #f4f9fd; margin: 8px"
            >
              <img src="../../../assets/images/client/circle.svg" alt="Snow" />
            </button>
            <button
              type="button"
              @click="HandelClickMultipleDelete()"
              class="btn notofication-btn"
              style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px"
            >
              <img src="../../../assets/images/client/delete.png" alt="Snow" />
            </button>
          </b-popover> -->
        </div>
      </div>
  
      
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
      </div>
      <div class="d-flex justify-content-center align-items-center" v-else-if="couponSuggestionData.length == 0">
        <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
      </div>
      <div v-else class="drop-zone">
        <draggable v-model="couponSuggestionData" handle=".draggable-task-handle" tag="ul"
          class="todo-task-list media-list draggable-task-handle d-inline">
          <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in couponSuggestionData" @dblclick="handelShow(item)"
            :key="index">
            <b-row class="p-1">
             <b-col md="3">
              <div class="d-flex align-items-center">
                <!-- <div>
                  <b-form-checkbox v-if="popoverShow" @change="checkboxClicked" v-model="checkBoxIds[item.id]" class="mr-0 mt-50" name="is-rtl"
                    inline />
                </div> -->
                <div class="ml-1 mr-1">
                  <b-img v-if="checkURL(item.brand_logo?item.brand_logo.split(',')[0]:'')" v-bind="mainProps" rounded="circle" :src="item.brand_logo?item.brand_logo.split(',')[0]:''"
                    alt="Circle image" class="d-inline-block" />
                  <b-img v-else v-bind="mainProps" rounded="circle"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block" />
                </div>
                <div class="ml-1  mr-1">
                  <div class="font-weight-bolder text-black mb-half">
                  {{ item.fullname ? item.fullname : '(not set)' }}
                  </div>
                  <div style="max-width: 15cm;">{{ item.email }}</div>
                </div>
              </div>
            </b-col>
              <b-col md="2" class="d-flex">
                <div>
                  <div class="mb-half">Coupon Code</div>
                  <div class="font-weight-bolder text-black">
                      {{ item.coupon_code ? item.coupon_code.slice(0,15) : ''}}{{ item.coupon_code ? item.coupon_code.length > 15 ? '...' : '' : '' }}
                  </div>
                </div>
              </b-col>
              <b-col md="1" class="d-flex">
                <div>
                  <div class="mb-half">Store</div>
                  <div class="font-weight-bolder text-black">
                      {{ item.store}}
                  </div>
                </div>
              </b-col>
              <b-col md="2" class="d-flex">
                <div>
                  <div class="mb-half">Phone Number</div>
                  <div class="font-weight-bolder text-black">
                      {{ item.phone_number}}
                  </div>
                </div>
              </b-col>
              <b-col md="2" class="d-flex ">
                <div>
                  <div class="mb-half">Created At</div>
                  <div class="font-weight-bolder text-black">{{ item.created_at }}</div>
                </div>
              </b-col>
              <b-col md="2" class="d-flex justify-content-center align-items-center">
                <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 8px 20px;"
                  v-b-modal="'myModal'" 
                @click="handelShow(item)"> View</button>
              </b-col>
            </b-row>
            <!-- <b-popover
            :target="'popover-button-variant'+item.id"
            triggers="click"
            class="custom-popover"
            :show.sync="popoverShow3[item.id]"
          >
            <button
              @click="onPopovcerOpen3(item.id)"
              class="notofication-btn"
              style="color: #408dff; margin: 5px"
              size="sm"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn notofication-btn ml-1"
              @click="handelEdit(item.id)"
              v-b-modal.modal
              style="background: rgb(63 140 254 / 13%); margin: 8px"
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square" viewBox="0 0 16 16">
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
            </button>
            <button
              type="button"
              class="btn notofication-btn"
              @click="handelDelete(item.id)"
              style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px"
            >
              <img src="../../../assets/images/client/delete.png" alt="Snow" />
            </button>
          </b-popover> -->
          </b-card>
        </draggable>
        <div class="no-results" :class="{ show: !couponSuggestionData.length }"></div>
      </div>
      <button v-if="!isLoading && couponSuggestionData.length" type="button" class="w-auto btn slide-btn">
        <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
      </button>
  
      <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
        <div class="d-flex justify-content-around align-items-center">
          <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
        </div>
      </button>
    </section>
  </template>
  
  <script>
  import draggable from "vuedraggable";
  import { BImg } from 'bootstrap-vue'
  import json2csv from 'json2csv';
  import flatPickr from 'vue-flatpickr-component';
  import moment from 'moment';
  import 'flatpickr/dist/flatpickr.css';
  import vSelect from 'vue-select';
  // eslint-disable-next-line
  import 'quill/dist/quill.core.css'
  // eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
  // eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'
  import { quillEditor } from 'vue-quill-editor'
  import { getCouponSuggestionData } from '@/api/clientstore';
  export default {
    components: {
      draggable,
      vSelect,
      flatPickr,
      quillEditor, 
      BImg
    },
    data() {
      return {
        mainProps: {
          width: 70,
          height: 70,
          class: 'm1',
        },
        data: {},
        filter: 'All',
        selectAllLabel: 'Select All',
        baseURL: process.env.VUE_APP_BASE_URL,
        host: window.location.origin,
        categoryOptions: [],
        couponSuggestionData: [],
        couponSuggestionData1: [],
        popoverShow: false,
        showItem:{},
        date:'',
        edit: false,
        popoverShow1: false,
        pageNo: 1,
        paginationStart: 0,
        isLoading: false,
        paginationEnd: 10,
        paginationLength: 0,
        clientNameEng: '',
        clientNameEArb: '',
        category: [],
        category_ids: [],
        descriptionEng: '',
        descriptionArb: '',
        status: '',
        sequence: '',
        checkBoxIds: {},
        websiteUrl: '',
        imageUrl: '',
        selectAll: false,
        keywordEng: '',
        popoverShow3: {},
        keywordArb: '',
      };
    },
    created() {
    },
    mounted() {
        setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Coupon Suggestion')
      if(is_valid.length < 1){this.$router.push('/')}
    }, 500);
      this.getCouponSuggestion();
      this.getClientCatgoriesData();
    },
    computed: {
      search() {
        // let data = []
        let search = this.$store.state.searchData
        // data = this.couponSuggestionData1.filter(item => (item.user_name?.replace(/\s/g,'').toLowerCase().includes(search) 
        //                                              || item.id.toString().includes(search) 
        //                                              || item.email.replace(/\s/g,'').toLowerCase().includes(search) 
        //                                              || item.created.replace(/\s/g,'').toLowerCase().includes(search)
        //                                              || (item.is_active == 1 ? 'Active' : 'Inactive').replace(/\s/g,'').toLowerCase().includes(search)
        //                                              ))
        this.getSearch(search);
        this.date = ''
      },
      app() {
        setTimeout(() => {
      let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Coupon Suggestion')
      if(is_valid.length < 1){this.$router.push('/')}
    }, 500);
        this.$store.state.application
        this.getCouponSuggestion()
      }
    },
    methods: {
      
      handelShow(item){
        this.$refs.showmodal.show()
        this.popoverShow3={}
        this.showItem = item
      },
      getSearch: _.debounce(function (search) {
        this.pageNo = 1
        this.paginationStart = 0
        this.paginationEnd = 10
        // if(search){
        this.isLoading = true
        let date = this.date.toString().replace(/\s/g,'');
        let data = { 
            search: search, 
            pageno: this.pageNo, 
            app_id: this.$store.state.application.id,
            startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
            endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
          }
        this.$http.post(`${this.baseURL}/coupon-suggestion/search`, data,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            console.log(res, 'res')
            this.couponSuggestionData = res.data.data
            this.paginationLength = res.data.totaldata
            this.couponSuggestionData1 = res.data.data
            this.isLoading = false
          })
        // }
      }, 1000),
      getFilterData: _.debounce(function (e) {
        this.date = e
        this.getCouponSuggestion()
      }, 100),
      exportToCSV() {
        // Convert the data to CSV format using json2csv library
        // let array = []
        // this.couponSuggestionData.map(item => {
        //   let data = {
        //   name_en:item.name_en,
        //   name_ar:item.name_ar,
        //   category: item.categories.map((item)=>{ return item.name}).join(", "),
        //   description_en:item.description_en,
        //   description_ar:item.description_ar,
        //   is_active:item.is_active,
        //   sequence:item.sequence,
        //   website:item.website,
        //   image:item.image,
        //   search_keyword_en:item.search_keyword_en,
        //   search_keyword_ar:item.search_keyword_ar,
        // }
        // array.push(data);
        // })
        const csvData = json2csv.parse(this.couponSuggestionData)
          ;
        const link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
        link.download = 'CouponSuggestion.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      closeModal() {
        this.$refs['modal'].hide()
      },
      handelChangeFilter() {
        console.log(this.filter)
        if (this.filter == "All") {
          this.couponSuggestionData = this.couponSuggestionData1
        }
        else if (this.filter == "Active") {
          let data = []
          data = this.couponSuggestionData1.filter(item => item.is_active == 1)
          this.couponSuggestionData = data
        }
        else if (this.filter == "Inactive") {
          let data = []
          data = this.couponSuggestionData1.filter(item => item.is_active == 0)
          this.couponSuggestionData = data
        }
      },
      onPopovcerOpen3(id) {
        this.popoverShow3[id] = this.popoverShow3[id] == undefined ? false : this.popoverShow3[id];
        this.popoverShow3[id] = this.popoverShow3[id] == true ? false : true;
        this.$forceUpdate()
      },
      pagechange(){
        if(this.pageNo>(this.paginationLength/10)){
          this.pageNo = parseInt(this.paginationLength/10)
        }
        if(this.pageNo == 0){
          this.pageNo = 1
        }
          this.paginationStart = (this.pageNo*10)-10
          this.paginationEnd = (this.pageNo*10)
        let search = this.$store.state.searchData
            if (search) {
              this.searchPaginationHandelClick(search)
            } else {
              this.normalPaginationHandelClick(search)
            }
      },
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url ? url.replace(/\s/g,'%20') : url);
    },
      searchPaginationHandelClick: _.debounce(function (search) {
        this.isLoading = true
        let date = this.date.toString().replace(/\s/g,'');
        let data = { 
            search: search, 
            pageno: this.pageNo, 
            app_id: this.$store.state.application.id,
            startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
            endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
          }
        this.$http.post(`${this.baseURL}/coupon-suggestion/search`, data).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.couponSuggestionData = res.data.data
          // this.pageNo=res.data.page
          this.selectAllLabel = 'Select All'
          this.checkBoxIds = {}
          this.paginationLength = res.data.totaldata
          this.couponSuggestionData1 = res.data.data
          this.isLoading = false
        })
      }, 1000),
      normalPaginationHandelClick: _.debounce(function () {
        this.isLoading = true 
          let date = this.date.toString().replace(/\s/g,'');
        let data = { 
            pageno: this.pageNo, 
            app_id: this.$store.state.application.id,
            startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
            endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
          }
        this.$http.post(`${this.baseURL}/coupon-suggestion/index`,data).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.couponSuggestionData = res.data.data
          this.selectAllLabel = 'Select All'
          this.checkBoxIds = {}
          // this.pageNo=res.data.page
          this.paginationLength = res.data.totaldata
          this.couponSuggestionData1 = res.data.data
          this.isLoading = false
        })
      }, 1000),
      paginationHandelClick(value) {
        let search = this.$store.state.searchData
        if (value == 'back') {
          if (this.paginationStart > 1) {
            this.pageNo--
            this.paginationStart = this.paginationStart - 10
            this.paginationEnd = this.paginationEnd - 10
            if (search) {
              this.searchPaginationHandelClick(search, value)
            } else {
              this.normalPaginationHandelClick(search, value)
            }
          }
        }
        else if (value == 'next') {
          if (this.paginationEnd < this.paginationLength) {
            this.pageNo++
            this.paginationStart = this.paginationStart + 10
            this.paginationEnd = this.paginationEnd + 10
            if (search) {
              this.searchPaginationHandelClick(search, value)
            } else {
              this.normalPaginationHandelClick(search, value)
            }
          }
        }
      },
      HandelClickMultipleDelete() {
        let arr = []
        Object.keys(this.checkBoxIds).map((key) => {
          if (this.checkBoxIds[key] == true) {
            arr.push(key)
          }
        });
        this.$http.post(`${this.baseURL}/coupon-suggestion/multi-delete`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Deleted",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getCouponSuggestion()
            this.popoverShow= false
            this.checkBoxIds = {}
            this.$forceUpdate()
          })
      },
      handelDelete(id) {
        this.$http.post(`${this.baseURL}/coupon-suggestion/delete`, { id: id },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Deleted",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getCouponSuggestion()
            this.onPopovcerOpen3(id)
            this.checkBoxIds = {}
            this.popoverShow3[id] = false
            this.$forceUpdate()
          })
      },
      selectAllClick() {
        let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
        console.log(data)
        if (data.length == 10) {
          this.selectAllLabel = 'Select All'
          this.checkBoxIds = {}
        } else {
          this.selectAllLabel = 'Deselect All'
          // console.log('enter',this.paginationStart,this.paginationEnd,this.couponSuggestionData,this.checkBoxIds)
          for (let x = this.paginationStart; x < this.paginationEnd; x++) {
            this.checkBoxIds[this.couponSuggestionData[x].id] = true
          }
        }
        this.$forceUpdate()
      },
      CheckBoxHandelClick(id) {
        // this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
        // this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
        let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
        if (data.length == 10) {
          this.selectAllLabel = 'Deselect All'
        } else {
          this.selectAllLabel = 'Select All'
        }
        this.$forceUpdate()
      },
      onPopovcerOpen() {
        this.popoverShow = !this.popoverShow;
      },
      onPopovcerOpen1() {
        this.popoverShow1 = !this.popoverShow1;
      },
      async getCouponSuggestion() {
        if (this.$store.state.application) {
          this.isLoading = true
          let date = this.date.toString().replace(/\s/g,'');
          let data = { 
            pageno: 1, 
            app_id: this.$store.state.application.id,
            startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
            endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
          }
          console.log(data,"data-=-=-")
          this.$http.post(`${this.baseURL}/coupon-suggestion/index`, data).then((res) => {
            console.log(res.data.data, "res-=-=")
            this.couponSuggestionData = res.data.data
            this.paginationLength = res.data.totaldata
            this.couponSuggestionData1 = res.data.data
            this.isLoading = false
          })
        }
        // let data = await getcouponSuggestionData();
        // console.log(data,"data=====");
      },
      getClientCatgoriesData(){
          this.$http.post(`${this.baseURL}/client-categories/index`).then((res) => {
            console.log(res.data.data, "res-=-=")
            this.categoryOptions = res.data.data
            this.checkBoxIds = {}
          })
        }
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/pages/dashboard-ecommerce.scss";
  @import "@core/scss/vue/libs/chart-apex.scss";
  // .draggable-task-handle {
  //     transform: translateY(-50%);
  //     visibility:visible;
  //     cursor: move;
  
  //     .todo-task-list .todo-item:hover & {
  //       visibility: hidden;
  //     }
  // }
  
  .success-btn {
    width: 58px;
    padding: 3px 5px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #0ac947;
    border: 1px solid #0ac947;
    border-radius: 4px;
  }
  
  .danger-btn {
    width: 58px;
    padding: 3px 5px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #c90a0a;
    border: 1px solid #c90a0a;
    border-radius: 4px;
  }
  
  .mb-half {
    margin-bottom: 5px;
  }
  
  .notofication-btn {
    background-color: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    padding: 12px 12px;
  }
  
  .slide-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    padding: 15 px;
    margin: 0;
    width: 200px;
    float: right;
  }
  
  .see-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    width: 125px;
  }
  
  .bs-popover-left {
    border-radius: 14px !important;
    border: transparent;
    min-width: 140px !important;
    max-width: 350px !important;
  }
  
  .bs-popover-left .popover-body {
    display: flex;
    justify-content: space-between;
    border: none;
  }
  
  .bs-popover-bottom {
    background-color: #ffffff;
    // height: 190px;
    border: transparent;
    border: none;
    border-radius: 14px;
  }
  
  .popover-body {
    padding: 0.65rem 1.21rem !important;
    border: none !important;
  }
  
  .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #ffffff;
  }
  
  .vs-select__options__content {
    background-color: #ffffff !important;
  }
  
  .pop-btn {
    padding: 0 !important;
  }
  
  .modal-body {
    padding: 0px 40px 40px 40px !important
  }
  
  .form-control {
    border-radius: 14px;
  }
  
  .modal-content {
    border-radius: 24px;
  }
  
  .modal-header {
    border-radius: 24px;
  height: 77px;
  }
  
  textarea.form-control {
    min-height: 150px !important;
  }
  
  .popover1-body {
    padding: 0;
  }
  
  .modal-footer {
    display: none;
  }
  
  .modal-footer1 {
    display: flex;
    float: right;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.8rem 1.4rem;
  }
  </style>
  